import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image';
import BlockSwipeable from './BlockSwipeable';
import leftArrow from '../../../../../images/indexPage/videoBlock/arrow_left.svg';
import rightArrow from '../../../../../images/indexPage/videoBlock/arrow_right.svg';

const Slider = styled(Grid)`
  width: 100%;
  flex-grow: 1;
  text-align: center;
  height: 100%;
`;

const BlockSlider = ({
  slidesData,
  data,
  activeStep,
  lang,
  handleStepChange,
  centered,
  maxSteps,
}) => {
  return (
    <Slider container justify="center" alignItems="center" wrap="nowrap">
      <div
        style={{ width: '10em', height: '10em', cursor: 'pointer' }}
        onClick={() =>
          activeStep > 0
            ? handleStepChange(activeStep - 1)
            : handleStepChange(maxSteps - 1)
        }
      >
        <img src={leftArrow} loading="lazy" />
      </div>
      <BlockSwipeable
        activeStep={activeStep}
        data={data}
        lang={lang}
        slidesData={slidesData}
        handleStepChange={handleStepChange}
        centered={centered}
      />
      <div
        style={{ width: '10em', height: '10em', cursor: 'pointer' }}
        onClick={() =>
          activeStep !== maxSteps - 1
            ? handleStepChange(activeStep + 1)
            : handleStepChange(0)
        }
      >
        <img src={rightArrow} loading="lazy" />
      </div>
    </Slider>
  );
};
export default BlockSlider;
