import React, { useState } from 'react';
import styled from 'styled-components';
import Markdown from 'src/utils/markdownToComponent';
import { Grid, Typography } from '@material-ui/core';
import { Button } from 'components/basics';
import { breakpoint } from 'src/utils/mediaQueries';
import { colors, textBold } from 'styles';
import { Link } from 'gatsby';
import phone from 'images/solutionPage/analyseVerbatim/linksBlock/phone.png';
import { FORM_TYPES } from 'components/pages/Index/FormModals/formConfigs';
import FormModal from 'components/pages/Index/FormModals/FormModal';

const ContactImage = styled(Grid)`
  width: auto;
  margin-top: 8px;
  padding-left: 0.5rem;
  img {
    max-width: 2.5rem;
    ${breakpoint.down('lg')`
    max-width: 1.8rem`}
    ${breakpoint.down('md')`
    max-width: 2.7rem`}
  }
`;
const StyledMarkdown = styled(Markdown)`
  ${textBold}
  ${breakpoint.only('md')`font-size: 24px`}
`;

const PreTitle = styled(Typography)`
  color: ${props => colors[props.customcolor]};
  ${textBold}
`;
const Container = styled(Grid)`
  text-align: center;
  ${breakpoint.down('md')`&:not(:last-child){margin-bottom: 4rem}`}
  ${breakpoint.down('sm')`&:not(:last-child){margin-bottom: 2rem}`}
`;

const LinkBlock = ({ data, lang }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (data.frontmatter.color !== 'lightBlue') {
    return (
      <Container item xs={12} sm={12} md={4} lg={4}>
        <PreTitle customcolor={data.frontmatter.color}>
          {data.frontmatter.subTitle}
        </PreTitle>
        <StyledMarkdown variant="h6">{data.rawMarkdownBody}</StyledMarkdown>
        <Button
          size="large"
          variant={'contained'}
          customBackgroundColor={colors[data.frontmatter.color]}
          customHoverBackgroundColor={
            colors.shades[data.frontmatter.color].dark
          }
          color="primary"
          mt={1}
          component={Link}
          to={`/${lang}/${data.frontmatter.link}`}
        >
          {data.frontmatter.buttonText}
        </Button>
      </Container>
    );
  } else {
    return (
      <Container item xs={12} sm={12} md={4} lg={4}>
        <PreTitle customcolor={data.frontmatter.color}>
          {data.frontmatter.subTitle}
        </PreTitle>
        <StyledMarkdown variant="h6">{data.rawMarkdownBody}</StyledMarkdown>
        <Grid container justify="center">
          <Button
            onClick={() => handleOpen()}
            size="large"
            variant={'contained'}
            customBackgroundColor={colors[data.frontmatter.color]}
            customHoverBackgroundColor={
              colors.shades[data.frontmatter.color].dark
            }
            color="primary"
            mt={1}
          >
            {data.frontmatter.buttonText}
          </Button>

          <ContactImage container alignItems="center">
            <a href={'tel:+330784960436'}>
              <img src={phone} alt="phone" loading="lazy" />
            </a>
          </ContactImage>
          <FormModal
            lang={lang}
            open={open}
            handleClose={handleClose}
            type={FORM_TYPES.CONTACT}
          />
        </Grid>
      </Container>
    );
  }
};

export default LinkBlock;
