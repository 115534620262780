import _ from 'lodash';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import Slide from 'components/pages/Solution/Marketing/SliderBlock/Slides/Slide';
import { Grid } from '@material-ui/core';


const BlockSwipeable = ({
  activeStep,
  handleStepChange,
  slidesData,
  data,
  lang,
  centered,
}) => (
  <SwipeableViews
    axis={'x'}
    index={activeStep}
    onChangeIndex={handleStepChange}
    enableMouseEvents
    springConfig={{
      duration: '0.35s',
      easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
      delay: '0s',
    }}
  >
    {slidesData.map((step, index) => {
      const imageSlider = _.find(data.images, function(image) {
        return image.base === step.frontmatter.relatedImage;
      });
      const isEven = index % 2 === 0;
      const imageToShow = imageSlider
        ? imageSlider.childImageSharp.fluid
        : null;
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          key={`OddSlide${index}`}
        >
          <Slide
            activeStep={activeStep}
            step={step}
            imageToShow={imageToShow}
            index={index}
            lang={lang}
            directionSlide={
              !centered ? (isEven ? 'row' : 'row-reverse') : 'row'
            }
            textAlign={!centered ? (isEven ? 'right' : 'left') : 'center'}
            centered={centered}
            imagesSliderQuotes={data.imagesSliderQuotes}
          />
        </Grid>
      );
    })}
  </SwipeableViews>
);
export default BlockSwipeable;
