import BlockText from './BlockText';
import BlockImage from './BlockImage';
import { Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Content } from 'components/basics';

const StyledGrid = styled(Grid)`
  padding: 1rem;
  ${Content};
  flex-direction: ${({ centered }) => centered && 'column'};
`;

export const ImageGrid = styled(Grid)`
  max-width: 200px;
  width: 100%;
  height: 200px;
  display: flex;
`;

export const QuoteImage = styled.img`
  width: 100%;
`;

const Slide = ({
  index,
  lang,
  activeStep,
  step,
  imageToShow,
  directionSlide,
  textAlign,
  centered,
  imagesSliderQuotes,
}) => (
  <StyledGrid
    container
    direction={directionSlide}
    wrap={!centered ? 'nowrap' : 'wrap'}
    justify="center"
    key={`Slides${index}`}
    alignItems="center"
    centered={centered ? 1 : 0}
  >
    <ImageGrid container alignItems="center" justify="center">
      <QuoteImage
        src={
          imagesSliderQuotes &&
          imagesSliderQuotes.find(
            image => image.base === step.frontmatter.image
          ).childImageSharp.fluid.src
        }
      />
    </ImageGrid>
    <BlockText step={step} textAlign={textAlign} />
    <BlockImage
      imageToShow={imageToShow}
      activeStep={activeStep}
      index={index}
    />
  </StyledGrid>
);
export default Slide;
