import { colors, textBold } from 'styles';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { breakpoint } from 'utils/mediaQueries';
import Markdown from 'utils/markdownToComponent';
import { YoutubeVideoEmbed } from '../../../../../basics';

const Slide = styled(Grid)`
  height: 100%;
  text-align: ${props => props.textalign};
  ${breakpoint.down('md')`
   text-align: center`}
`;
const SlideTitle = styled(Typography)`
  width: 100%;
  padding-bottom: 2rem;
  text-align: ${props => props.textalign};
  font-size: ${props => `${props.theme.typography.h5.fontSize}px`};
  line-height: 1.2em;
  ${breakpoint.down('md')`
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  font-size: ${props => `${props.theme.typography.body1.fontSize}px`};`};
  ${breakpoint.only('xxs')`font-size: ${props =>
    `${props.theme.typography.body1.fontSize}px`};max-width: 200px;`}
`;
const SlideSubtitle = styled(Typography)`
  color: ${colors.red};
  width: 100%;
  text-align: ${props => props.textalign};
  ${breakpoint.down('md')`
   text-align: center`}
  ${breakpoint.down('sm')`font-size: ${props =>
    `${props.theme.typography.body2.fontSize}px`};`}
`;
const SlideText = styled(Markdown)`
  font-size: ${props => `${props.theme.typography.subtitle2.fontSize}
px`};
  ${breakpoint.down('sm')`
font-size: ${props => `${props.theme.typography.body2.fontSize}px`};`}
  p {
    font-size: ${props => `${props.theme.typography.subtitle2.fontSize}
px`};
    ${breakpoint.down('sm')`
font-size: ${props => `${props.theme.typography.body2.fontSize}px`};`}
  }
`;

const SlideReferences = styled(Typography)`
  font-size: ${({ theme, author, role }) => {
    return author
      ? theme.typography.body1.fontSize
      : theme.typography.body2.fontSize;
  }}px;
  ${({ author }) => author && textBold}
`;

const BlockText = ({ step, textAlign, fromUseCase = false }) => {
  return (
    <Slide
      container
      direction="row"
      alignItems="center"
      justify="space-evenly"
      item
      xs={12}
      sm={9}
      md={6}
      lg={6}
      textalign={textAlign}
    >
      {!fromUseCase && (
        <>
          <SlideSubtitle variant="subtitle2" textalign={textAlign}>
            {step.frontmatter.subTitle}
          </SlideSubtitle>
          <SlideTitle variant="h3" textalign={textAlign}>
            {step.frontmatter.title}
          </SlideTitle>
        </>
      )}
      {step.frontmatter.youtubeID ? (
        <YoutubeVideoEmbed
          youtubeID={step.frontmatter.youtubeID}
          youtubeTitle={step.frontmatter.youtubeTitle}
          width={'560'}
          height={'315'}
        />
      ) : (
        <SlideText>{step.rawMarkdownBody}</SlideText>
      )}
      {step.frontmatter.author && (
        <span>
          <SlideReferences author={step.frontmatter.author}>
            {step.frontmatter.author}
          </SlideReferences>
          <SlideReferences role={step.frontmatter.role}>
            {step.frontmatter.role}
          </SlideReferences>
        </span>
      )}
    </Slide>
  );
};
export default BlockText;
