import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { breakpoint } from 'utils/mediaQueries';
const ImageSlider = styled(Img)`
  width: 100%;
  max-height: 450px;
  min-height: 450px;
  img {
    object-fit: contain !important;
  }
`;
const StyledGrid = styled(Grid)`
  margin: auto 2rem;
  ${breakpoint.down('md')`
   display: none`}
`;
const BlockImage = ({ imageToShow, activeStep, index }) => {
  return (
    <StyledGrid item xs={'auto'} sm={6} md={6} lg={6}>
      {Math.abs(activeStep - index) <= 2 ? (
        imageToShow ? (
          <ImageSlider fluid={imageToShow} />
        ) : null
      ) : null}
    </StyledGrid>
  );
};
export default BlockImage;
