import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { breakpoint } from 'utils/mediaQueries';
import BlockSlider from 'components/pages/Solution/Marketing/SliderBlock/BlockSlider';
import Markdown from 'utils/markdownToComponent';
import { textBold } from 'styles';
import DotsStepper from 'components/pages/Solution/Marketing/SliderBlock/DotsStepper';
import { breakpoints } from 'styles/breakpoints';

const ContentBloc = styled(Grid)`
  text-align: center;
  position: relative;
  padding-top: 4rem;
  ${breakpoint.down('md')`
  padding-top: 2rem`}
`;
const StyledMarkdown = styled(Markdown)`
  padding-bottom: 2rem;
  font-size: ${props => `${props.theme.typography.h5.fontSize}
px`};
  font-family: ${props => props.theme.typography.h5.fontFamily};
  ${textBold}
  .biggerText {
    font-size: ${props => `${props.theme.typography.h2.fontSize}
px`};
    ${breakpoint.down('md')`
    font-size: ${props => `${props.theme.typography.h6.fontSize}px`};`}
  }
  ${breakpoint.down('md')`
   text-align: center; padding: 2rem 0;font-size: ${props =>
     `${props.theme.typography.h6.fontSize}px`}`}
`;
const BottomDots = styled(Grid)`
  padding-top: 4rem;
  ${breakpoint.down('md')`
  padding-top: 0`}
  ${breakpoint.down('sm')`
  padding: 0 4rem;`}
`;
class SliderBlock extends React.Component {
  state = {
    activeStep: 0,
    maxSteps: null,
    slidesData: null,
    interval: false,
  };
  componentDidMount() {
    if (this.props.data) {
      this.setData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.data !== prevProps.data) {
      this.setData();
    }
  }

  setData = () => {
    const slidesData = this.props.data.markdownContent;
    const maxSteps = slidesData.length;
    this.setState({ maxSteps, slidesData });
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };
  changeInterval = () => {
    this.setState({ interval: !this.state.interval });
  };

  render() {
    const { data, lang } = this.props;
    const { maxSteps, slidesData } = this.state;
    const { activeStep } = this.state;
    const widthWindow =
      typeof window !== 'undefined' ? window.innerWidth : null;
    const isMobile = widthWindow > `${breakpoints.values.md}`;
    return (
      <ContentBloc
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        direction="column"
        justify="center"
        alignItems="center"
        onMouseEnter={() => this.changeInterval()}
        onMouseLeave={() => this.changeInterval()}
      >
        {data.title && (
          <StyledMarkdown>{data.title.rawMarkdownBody}</StyledMarkdown>
        )}
        {slidesData && (
          <>
            <BlockSlider
              slidesData={slidesData}
              lang={lang}
              data={data}
              maxSteps={maxSteps}
              activeStep={activeStep}
              handleStepChange={this.handleStepChange}
              isMobile={isMobile}
              centered={this.props.centered}
            />
            <BottomDots container item justify="center">
              <DotsStepper
                maxSteps={slidesData}
                activeStep={activeStep}
                handleStepChange={index => this.handleStepChange(index)}
              />
            </BottomDots>
          </>
        )}
      </ContentBloc>
    );
  }
}
export default SliderBlock;
