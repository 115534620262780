import React from 'react';
import LinkBlock from './LinkBlock';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { Container, Content } from 'components/basics';

const ContentBlock = styled(Grid)`
  padding-top: 8rem;
  ${Content}
`;

const LinkBlocks = ({ data, lang }) => {
  return (
    <Container>
      <ContentBlock container spacing={40} justify="center">
        {data &&
          data.map((block, index) => (
            <LinkBlock key={`LinkBlock${index}`} data={block} lang={lang} />
          ))}
      </ContentBlock>
    </Container>
  );
};

export default LinkBlocks;
